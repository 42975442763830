/* General Header Styles */
.header {
  position: sticky;
  top: 0;
  width: auto;
  background-color: #F2F0EF;
  padding: 6vh 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  height: 10vh;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Logo */
.header .logo-link {
  display: flex;
  align-items: center;
}

.header .brand-logo {
  max-height: 9vh;
  width: auto;
}

/* Navigation */
.header .nav-links {
  display: flex;
  align-items: center;
  gap: 30px;
}

.header .nav-links a {
  color: #0045f3;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  transition: color 0.3s ease, transform 0.2s ease-in-out, background-color 0.3s ease;
  padding: 10px 15px;
  border-radius: 5px;
}

.header .nav-links a:hover,
.header .nav-links a.active {
  color: #287BFF;
  background-color: rgba(0, 0, 0, 0.05);
  transform: scale(1.05);
}

/* Modern Hamburger Menu - Fixed alignment issue */
.header .hamburger {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  position: relative;
  width: 40px;
  height: 40px;
  transition: all 0.3s ease-in-out;
  z-index: 1002;
}

.hamburger-icon,
.hamburger-icon::before,
.hamburger-icon::after {
  width: 30px;
  height: 3px;
  background-color: #0045f3;
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out;
}

.hamburger-icon {
  top: 50%;
  transform: translate(-50%, -50%);
}

.hamburger-icon::before,
.hamburger-icon::after {
  content: '';
}

.hamburger-icon::before {
  top: -10px;
}

.hamburger-icon::after {
  bottom: -10px;
}

.hamburger.active .hamburger-icon {
  background-color: transparent;
}

.hamburger.active .hamburger-icon::before {
  top: 0;
  transform: translateX(-50%) rotate(45deg);
}

.hamburger.active .hamburger-icon::after {
  bottom: 0;
  transform: translateX(-50%) rotate(-45deg);
}

/* Dropdown Menu */
.dropdown {
  position: relative;
  display: flex;
  align-items: center;
}

.dropdown-header {
  display: flex;
  align-items: center;
}

.dropbtn {
  background: none;
  border: none;
  color: #0045f3;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 5px;
  transition: color 0.3s ease, background-color 0.3s ease, transform 0.2s ease-in-out;
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
}

.dropbtn:hover, 
.dropbtn.active {
  color: #287BFF;
  background-color: rgba(0, 0, 0, 0.05);
  transform: scale(1.05);
}

.dropdown-arrow {
  font-size: 12px;
  transition: transform 0.3s ease;
  cursor: pointer;
  padding: 5px;
  margin-left: 2px;
}

/* Dropdown Content */
.dropdown-content {
  display: none;
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #F2F0EF;
  min-width: 220px;
  border-radius: 8px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  z-index: 1001;
  overflow: hidden;
  padding: 8px 0;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.dropdown-content a {
  color: #0045f3 !important;
  padding: 12px 16px !important;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 16px;
  text-align: left;
}

.dropdown-content a:hover,
.dropdown-content a.active {
  background-color: #e6e6e6 !important;
  color: #287BFF !important;
  transform: none !important;
}

/* Show dropdown when active */
.dropdown-content.show {
  display: block;
  opacity: 1;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px) translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
  }
}

/* Mobile Styles */
@media (max-width: 992px) {
  .header {
    padding: 12px 20px;
  }
  
  .header .hamburger {
    display: block;
  }
  
  .header .nav-links {
    display: none;
    flex-direction: column;
    align-items: stretch;
    position: fixed;
    top: 10vh;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: 90vh;
    background-color: #F2F0EF;
    padding: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    z-index: 1001;
  }
  
  .header .nav-links.open {
    display: flex;
    animation: slideIn 0.3s ease-in-out;
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-20px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .header .nav-links a {
    width: auto;
    text-align: left;
    font-size: 18px;
    padding: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  /* Mobile Dropdown */
  .dropdown {
    width: auto;
    flex-direction: column;
    align-items: stretch;
  }
  
  .dropdown-header {
    display: flex;
    justify-content: space-between;
    width: auto;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 0;
  }
  
  .dropbtn {
    width: auto;
    text-align: left;
    padding: 15px;
    font-size: 18px;
    justify-content: space-between;
    border-radius: 0;
  }
  
  .dropdown-content {
    position: static;
    width: auto;
    transform: none;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    margin-top: 0;
    animation: none;
    border-left: 3px solid maroon;
    background-color: rgba(0, 0, 0, 0.02);
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
  }
  
  .dropdown-content.show {
    max-height: 1000px;
    animation: expandDown 0.5s ease-in-out;
  }
  
  @keyframes expandDown {
    from {
      max-height: 0;
    }
    to {
      max-height: 1000px;
    }
  }
  
  .dropdown-content a {
    padding: 15px 15px 15px 25px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  }
}

/* Accessibility improvements */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    transition-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
  }
}