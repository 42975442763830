
 .image-comparison-slider-container {
  width: 100%;
  max-width: 600px;
  margin: 30px auto;
  background-color: #f9f9f9; /* Change this to your desired background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  padding: 0;
  position: relative;
}

.image-border {
  border: 3px solid #0073e6;
  padding: 10px;
  background-color: transparent !important; /* Change white to transparent */
}

.image-comparison-slider-container canvas {
  background-color: transparent !important;
}

.image-comparison-slider-container div {
  background: transparent !important; /* Try overriding all child divs */
}
