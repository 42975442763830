/*****************************************************
  CSS Variables (Brand Colors & Common Reusables)
*****************************************************/
:root {
  --primary-blue: #1a6baa;
  --primary-blue-dark: #0d5691;
  --primary-blue-light: #3a8dcf;
  
  --accent-red: #e53935;
  --accent-red-dark: #c62828;
  --accent-red-light: #ef5350;

  --text-dark: #252525;
  --text-medium: #585858;
  --text-light: #f5f5f5;

  --gray-light: #f4f6f8;
  --gray-medium: #e1e5ea;
  --gray-dark: #b0b7be;
  
  --white: #ffffff;
  --black: #000000;

  --shadow-soft: 0 4px 6px rgba(0, 0, 0, 0.05);
  --shadow-medium: 0 8px 15px rgba(0, 0, 0, 0.08);

  --transition-standard: all 0.3s ease;
  
  --font-family: 'Roboto', 'Segoe UI', sans-serif;
}

/*****************************************************
  Global / Utility Styles
*****************************************************/
body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  font-family: var(--font-family);
  color: var(--text-dark);
  line-height: 1.6;
  background-color: #F2F0EF;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

button {
  cursor: pointer;
  font: inherit;
}

img, video {
  display: block;
  max-width: 100%;
}

/*****************************************************
  Container for the Entire Service Page
*****************************************************/
.service-detail-page {
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
}

/*****************************************************
  Back Navigation Bar
*****************************************************/
.back-navigation {
  padding: 1.5rem;
  background-color: #46B8FF;
}

.back-link {
  display: inline-flex;
  align-items: center;
  color: var(--white);
  font-weight: 600;
  transition: var(--transition-standard);
  padding: 0.5rem 0;
}

.back-link i {
  margin-right: 0.5rem;
  color: var(--white);
}

.back-link:hover {
  color: var(--gray-dark);
}

/*****************************************************
  Hero Section with Video
*****************************************************/
.detail-hero-section {
  position: relative;
  height: 85vh;
  max-height: 720px;
  min-height: 480px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.detail-video-container {
  position: relative;
  width: 100%;
  max-width: 1400px;
  height: 100%;
  border-bottom-left-radius: 10vh;
  border-bottom-right-radius: 10vh;  
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.detail-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.detail-video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10%;
}

.detail-video-overlay h1 {
  color: var(--white);
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 5px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  animation: fadeInUp 0.8s ease-out;
}

.detail-video-overlay p {
  color: var(--white);
  font-size: 1.5rem;
  max-width: 600px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  animation: fadeInUp 1s ease-out;
}

.detail-play-pause-btn {
  position: absolute;
  bottom: 3.5rem;
  right: 2rem;
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--white);
  font-size: 1.5rem;
  transition: var(--transition-standard);
  backdrop-filter: blur(4px);
}

.detail-play-pause-btn:hover {
  background-color: rgba(255, 255, 255, 0.3);
  transform: scale(1.05);
}

/*****************************************************
  Main Content Section with Tabs
*****************************************************/
.detail-content-section {
  background-color: var(--white);
  padding: 2rem;
  border-radius: 6px;
  margin-top: -3rem;
  position: relative;
  z-index: 10;
  box-shadow: var(--shadow-medium);
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8vh;

}

.detail-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  border-bottom: 1px solid var(--gray-medium);
}

.detail-tab {
  background: none;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--text-medium);
  cursor: pointer;
  transition: var(--transition-standard);
  position: relative;
}

.detail-tab:after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 0;
  height: 3px;
  background-color: var(--primary-blue);
  transition: var(--transition-standard);
}

.detail-tab:hover {
  color: var(--primary-blue);
}

.detail-tab.active {
  color: var(--primary-blue);
}

.detail-tab.active:after {
  width: 100%;
}

/*****************************************************
  Overview Tab - Content
*****************************************************/
.overview-content {
  animation: fadeIn 0.5s ease-out;
}

.detail-intro {
  margin-bottom: 3rem;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.detail-intro h2 {
  color: var(--primary-blue);
  font-size: 2rem;
  margin-bottom: 1rem;
}

.detail-intro p {
  font-size: 1.1rem;
  color: var(--text-medium);
}

.quick-quote {
  display: flex;  /* Enables flexbox */
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically if needed */
  text-align: center;

}

/* .contact-button can be reused anywhere as a CTA */
.contact-button {
  display: inline-block;
  margin-top: 20px; 
  padding: 10px 20px;
  background-color: var(--accent-red);
  color: var(--white);
  font-size: 1.3rem;
  font-weight: 600;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  width: 13rem;
  text-align: center;
  animation: fadeIn 2s ease-in-out;
}

.contact-button:hover {
  background-color: var(--accent-red-dark);
}

/*****************************************************
  Service Features - Grid
*****************************************************/
.service-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
}

.feature {
  background-color: var(--gray-light);
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  transition: var(--transition-standard);
  box-shadow: var(--shadow-soft);
}

.feature:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-medium);
}

/* 
  Ensuring icons remain consistent in size 
  and do not distort on smaller screens.
*/
.feature-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  min-width: 4rem; /* Prevent weird shrinking */
  min-height: 4rem;
  border-radius: 50%;
  background-color: var(--primary-blue-light);
  color: var(--white);
  font-size: 2rem;
  flex-shrink: 0;
}

.feature h3 {
  color: var(--primary-blue);
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  font-size: 1.3rem;
}

.feature p {
  color: var(--text-medium);
  line-height: 1.4;
}

/*****************************************************
  Service Process 
*****************************************************/
.service-process {
  margin-top: 4rem;
}

.service-process h2 {
  text-align: center;
  color: var(--primary-blue);
  font-size: 2rem;
  margin-bottom: 3rem;
}

.process-steps {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 800px;
  margin: 0 auto;
}

.process-step {
  display: flex;
  align-items: flex-start;
  background-color: var(--gray-light);
  padding: 1.5rem;
  border-radius: 8px;
  position: relative;
  transition: var(--transition-standard);
}

.process-step:hover {
  box-shadow: var(--shadow-medium);
  transform: translateY(-5px);

}

.step-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  /* background-color: var(--accent-red); */
  background-color: #46B8FF;
  color: var(--white);
  border-radius: 50%;
  font-weight: 700;
  margin-right: 1.5rem;

  font-size: 1.2rem;
  flex-shrink: 0;
}



.process-step h3 {
  color: var(--primary-blue);
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  margin-right: 0.5rem;
}

/*****************************************************
  FAQ Tab - Content
*****************************************************/
.faqs-content {
  animation: fadeIn 0.5s ease-out;
  max-width: 800px;
  margin: 0 auto;
}

.faqs-content h2 {
  text-align: center;
  color: var(--primary-blue);
  font-size: 2rem;
  margin-bottom: 2rem;
}

.faq-list {
  margin: 0;
  padding: 0;
}

.faq-item {
  margin-bottom: 1rem;
  border: 1px solid var(--gray-medium);
  border-radius: 8px;
  overflow: hidden;
}

.faq-question {
  padding: 1.2rem;
  background-color: var(--gray-light);
  font-weight: 600;
  color: var(--text-dark);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: var(--transition-standard);
}

.faq-question:hover {
  background-color: var(--gray-medium);
}

.faq-question.active {
  background-color: var(--primary-blue);
  color: var(--white);
}

.faq-icon {
  margin-left: 1rem;
  transition: var(--transition-standard);
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
  background-color: var(--white);
  padding: 0 1.2rem;
}

.faq-answer.active {
  max-height: 300px;
  padding: 1.2rem;
}

/*****************************************************
  Pricing Tab - Content
*****************************************************/
.pricing-content {
  animation: fadeIn 0.5s ease-out;
}

.pricing-content h2 {
  text-align: center;
  color: var(--primary-blue);
  font-size: 2rem;
  margin-bottom: 1rem;
}

.pricing-intro {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 3rem;
  color: var(--text-medium);
}

.pricing-tables {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}

.pricing-table {
  background-color: var(--white);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: var(--shadow-soft);
  transition: var(--transition-standard);
}

.pricing-table:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-medium);
}

.pricing-header {
  background-color: var(--primary-blue);
  color: var(--white);
  padding: 1.5rem;
  text-align: center;
}

.pricing-header h3 {
  font-size: 1.3rem;
  margin: 0;
}

.pricing-body {
  padding: 1.5rem;
}

.pricing-item {
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 0;
  border-bottom: 1px solid var(--gray-medium);
}

.pricing-item:last-child {
  border-bottom: none;
}

.item-name {
  color: var(--text-dark);
}

.price-range {
  font-weight: 600;
  color: var(--primary-blue);
}

.pricing-notes {
  max-width: 800px;
  margin: 2rem auto 0;
  text-align: center;
}

.quote-cta {
  margin-top: 2rem;
  text-align: center;
}

/* Reusable large CTA button */
.quote-button {
  display: inline-block;
  background-color: var(--accent-red);
  color: var(--white);
  padding: 1rem 2rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: var(--transition-standard);
}

.quote-button:hover {
  background-color: var(--accent-red-dark);
  transform: translateY(-2px);
  box-shadow: var(--shadow-medium);
}

/*****************************************************
  Keyframe Animations
*****************************************************/
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* ==============================
   Commercial Clients Section
   ============================== */

   .commercial-clients {
    margin-top: 4rem;
    text-align: center;
  }
  
  .commercial-clients h2 {
    color: var(--primary-blue);
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  
  /* Container that holds all the client-type cards */
  .client-types {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 2rem;
    margin: 0 auto;
    max-width: 1200px;
  }
  
  /* Each individual client-type card */
  .client-type {
    position: relative;
    background-color: var(--gray-light);
    border-radius: 8px;

    padding: 1.5rem;
    transition: var(--transition-standard);
    text-align: center;
  }
  .client-type::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 5px; /* thickness of the strip */
    height: 100%; /* full height of the card */
    background-color: var(--primary-blue); /* your accent color */
    border-top-right-radius: 16px; /* match the card's border radius */
    border-bottom-right-radius: 16px;
  }
  
  .client-type:hover {
    box-shadow: var(--shadow-medium);
    transform: translateY(-5px);
  }
  
  .client-type h3 {
    color: var(--primary-blue);
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
  }
  
  .client-type p {
    color: var(--text-medium);
    font-size: 1rem;
    line-height: 1.4;
  }
  

/*****************************************************
  Responsive Media Queries
*****************************************************/
@media (max-width: 992px) {
  .detail-hero-section {
    height: 70vh;
  }

  .detail-video-overlay h1 {
    font-size: 2.8rem;
  }

  .detail-video-overlay p {
    font-size: 1.3rem;
  }

  .detail-play-pause-btn {
    width: 3.5rem;
    height: 3.5rem;
  }

  .service-features {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .detail-hero-section {
    height: 60vh;
    min-height: 400px;
  }

  .detail-video-overlay {
    padding: 0 5%;
  }

  .detail-video-overlay h1 {
    font-size: 2.4rem;
  }

  .detail-video-overlay p {
    font-size: 1.1rem;
  }

  .detail-play-pause-btn {
    width: 3rem;
    height: 3rem;
    bottom: 2.5rem;
    right: 1.5rem;
  }

  .detail-content-section {
    padding: 1.5rem;
    margin-top: -2rem;
  }

  /* Re-styling tabs for smaller screens */
  .detail-tabs {
    flex-direction: row;
    justify-content: center;
    background: var(--gray-light);
    border-radius: 8px;
    padding: 0.5rem;
    box-shadow: var(--shadow-soft);
    margin-bottom: 1.5rem;
    border-bottom: none;
  }

  .detail-tab {
    flex: 1;
    text-align: center;
    background: var(--white);
    border-radius: 5px;
    padding: 0.8rem 1.2rem;
    font-size: 1rem;
    font-weight: 600;
    color: var(--text-medium);
    border: 1px solid var(--gray-medium);
    margin: 0 0.5rem;
  }

  .detail-tab:hover {
    background: var(--gray-medium);
    color: var(--primary-blue);
  }

  .detail-tab.active {
    /* background: var(--accent-red-light); */
    background: #46B8FF;
    color: var(--white);
    border: 1px solid var(--accent-red-dark);
  }

  .service-features {
    grid-template-columns: 1fr;
  }

  .process-step {
    flex-direction: column;
  }

  .step-number {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .pricing-tables {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}

@media (max-width: 480px) {
  .detail-hero-section {
    height: 50vh;
    min-height: 350px;
  }

  .detail-video-overlay h1 {
    font-size: 2rem;
  }

  .detail-video-overlay p {
    font-size: 1rem;
  }

  /* For very small screens, let tabs stack vertically */
  .detail-tabs {
    flex-direction: column;
    align-items: center;
  }
  
  .detail-tab {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid var(--gray-medium);
    margin: 0.25rem 0;
  }

  .detail-tab:last-child {
    border-bottom: none;
  }

  .feature-icon {
    width: 3.5rem;
    height: 3.5rem;
    font-size: 1.8rem;
  }

  .pricing-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .price-range {
    margin-top: 0.5rem;
  }
}
